import type { App, DefineComponent } from 'vue'
import { defineAsyncComponent } from 'vue'

export const registerComponents = (app: App) => {
  const modules = import.meta.glob<boolean, string, DefineComponent>('./components/*.vue')

  for (const path in modules) {
    const mod = modules[path]
    const parts = path.split('/')
    const name = parts[parts.length - 1].replace('.vue', '')

    app.component(name, defineAsyncComponent(mod))
  }
}
